.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #E7ECEF;
  perspective: 1000px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #6096BA;
  color: #E7ECEF;
}

/* Style the back side */
.flip-card-back {
  background-color: #A3CEF1;
  transform: rotateY(180deg);
}

/* ===== MEDIA QUERIES ===== */

/* MEDIUM DEVICES */
@media screen and (max-width: 1020px) {
  .jumbo_div {
    height: 400px !important;
  }
}


/* SMALL DEVICES */
@media screen and (max-width: 600px) {

  .flip-container {
    padding-left: 4.8rem;
  }
}